import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Container, Row } from "mdbreact";
import clientList from "../../assets/images/client-list.svg";
import Spinner from "../Utils/Spinner";
import pdf from "../../assets/actie_bourgon.pdf";

const Header = ({
    clientName,
    handleClientListClick,
    toggleLocale,
    lang,
    clientUserName,
    fairPoints
}) => {
    return (
        <Container fluid className="header">
            <Row>
                <Col size="9" className="header__client-info">
                    <span>
                        {`${
                            clientUserName && clientName
                            ? `${clientUserName} - ${clientName}`
                            : ""
                        }`}{" "}
                    </span>
                    {fairPoints.isLoading ? (
                        <Spinner
                            className="brand-color"
                            width={30}
                            height={30}
                        />
                    ) : (
                        <span>
                            <FormattedMessage id="header.client_points" />:{" "}
                            {fairPoints.data.rewardPoints || 0}{" "}
                            <FormattedMessage id="units.pts" />
                        </span>
                    )}{" "}
                </Col>
                <Col size="2">
                    <span
                        onClick={handleClientListClick}
                        className="header__client-list"
                        data-testid="client-list"
                    >
                        <FormattedMessage id="header.client_list" />
                        <img src={clientList} alt="client list" />
                    </span>
                </Col>
                <Col size="1">
                    <span
                        onClick={() => toggleLocale()}
                        className="header__language"
                    >
                        {lang === "nl" ? "FR" : "NL"}
                    </span>
                </Col>
            </Row>
        </Container>
    );
};

Header.propTypes = {
    clientName: PropTypes.string,
    clientPoints: PropTypes.number,
    maxPoints: PropTypes.number
};

export default Header;
